import * as globals from '../globals'
import {checkInput, checkInputOptional, checkValidationArr, setInitialForm, checkCheckBox} from '../functions';
import $ from "jquery";
$(function() {


   $('button.question,#navAnfrage').click(function (e) {
      e.preventDefault();
      document.querySelector('#sectionFormQuestion').scrollIntoView({
         behavior: 'smooth'
      });
   });
   $('#navFerienwohnung').click(function (e) {
      e.preventDefault();
      document.querySelector('#sectionFerienwohnungen').scrollIntoView({
         behavior: 'smooth'
      });
   });

   $('#name,#ort').on('keyup blur', function () {
      checkInput($(this), globals.patternAz);
   });
   $('#plz').on('keyup blur', function () {
      checkInput($(this), globals.pattern09);
   });
   $('#strasse').on('keyup blur', function () {
      checkInput($(this), globals.patternAz09);
   });
   $('#telefon').on('keyup blur', function () {
      checkInput($(this), globals.pattern09);
   });
   $('#email').on('keyup blur', function () {
      checkInputOptional($(this), globals.patternEmail);
   });

   $('#sendQuestion').click(function(e){
      e.preventDefault();
      e.stopImmediatePropagation();
      let obj = $(this);
      let form = $('#formQuestion');
      obj.find('.fa-check-circle,.fa-times-circle,.spinner-border').hide().find('.fa-angle-right').show();
      checkInput( $('#name'),  globals.patternAz);
      checkInput( $('#ort'),  globals.patternAz);
      checkInput( $('#plz'),  globals.pattern09);
      checkInput(  $('#strasse'),  globals.patternAz09);
      checkInput(  $('#telefon'),  globals.pattern09);
      checkInputOptional( $('#email'),  globals.patternEmail);
     // checkInputOptional( $('#nachricht'),  globals.patternText);
      checkCheckBox($('#privacy'));
      if( checkValidationArr() )
      {
         obj.find('.fa-angle-right').fadeOut(function(){
            obj.find('.spinner-border').show();
         });
         let params = $('#formQuestion').serializeArray();
         let anreise = $('#anreise').datetimepicker('date').format('YYYY-MM-DD');
         let abreise = $('#abreise').datetimepicker('date').format('YYYY-MM-DD');
         // symfony erwartet auf Serverseite ein Date im Format YYYY-MM-DD, deutsches format liegt vor -> im Param Array ersetzten
         params = params.map( val => {
            if( val.name === 'anreise' )
               val.value = anreise;
            else if ( val.name === 'abreise' )
            {
               val.value = abreise;
            }
            else if ( val.name === 'privacy' )
            {
               val.value = val.value === 'on' ? 1 : 0;
            }
            return val;
         });
         // entfernt alle leeren Felder, diese werden nicht mit übermittelt -> es dürften hier nur die optionalen fehlen
         params = params.filter( x => x.value !== '' )
         $.post(app.path.sendQuestion , params).done(data => {
            if(data.success !== undefined && data.success )
            {
               obj.find('.spinner-border').fadeOut(function(){
                  obj.find('.fa-check-circle').show();
                  form.find('.is-valid,.is-invalid').removeClass('is-valid is-invalid');
                  form[0].reset();
                  $('#anreise').datetimepicker('date', moment() );
                  $('#abreise').datetimepicker('date', moment().add(1,'days'));
               });
            }
            else
            {
               obj.find('.fa-times-circle').show();
            }
         }).fail( () =>{
            obj.find('.spinner-border').fadeOut(function(){
               obj.find('.fa-times-circle').show();
            });
         });
      }else
      {
         document.querySelector('#sectionFormQuestion').scrollIntoView({
            behavior: 'smooth'
         });
      }
   });
   let momentAbreise= moment();
   let momentAnreise= moment();
   let anreiseObj = $('#anreise');
   let abreiseObj = $('#abreise');

   anreiseObj.datetimepicker({ locale:'de', format: 'L',  'defaultDate': momentAbreise, 'minDate': momentAbreise});
   abreiseObj.datetimepicker({ locale:'de', format: 'L', 'minDate':momentAnreise.add(3,'days')});

   anreiseObj.on('hide.datetimepicker', function (e) {
      abreiseObj.datetimepicker('minDate',e.date.add(3,'days'));
   });/*.on('change.datetimepicker', function(e) {
        /* feuert nur das Event fuer das Inputfeld => sonst wird ein Event fuer INput und eines fuer den Datepicker gefeuert wird */
   /*  if (e.target.nodeName === "INPUT")
     {
        let obj = $(this), start = moment(obj.find('input').val(),'DD.MM.YYYY HH:mm');

     }
 });*/
});
import $ from "jquery";

export function cLog(val) {
    return app.env === 'dev' || app.env === 'test' ?  console.log(val) : '';
}

/* validiert einen Wert nach seinem Pattern */
export function validatePattern ( val, pattern){
    return parseInt( val.search( new RegExp( pattern , 'i') ) ) === 0;
}
export function checkCheckBox( obj, validationArrName = validationArr ){
    cLog('--check checkbox--');
    let id = obj.attr('id');
    validationArrName[ id ] = obj.is(':checked');
    console.log(validationArrName[id] );
    validationArrName[id] ? obj.removeClass('is-invalid') : obj.addClass('is-invalid');
}
export function checkInput( obj, pattern, validationArrName = validationArr ){
    cLog('--checkInput--');
    let val = obj.val(), origin = ( obj.data('origin') + '' ).trim(), id = obj.attr('id');
    if( val.length > 0 )
    {
            cLog('validate Pattern Result for id:' + id);
            cLog(validatePattern( obj.val() , pattern  ));
        if( val !== origin ) {
            if( validatePattern( obj.val() , pattern  ) )
            {
                obj.removeClass('is-invalid').addClass('is-valid');
                validationArrName[ id ] = true;
            }
            else
            {
                obj.removeClass('is-valid').addClass('is-invalid');
                validationArrName[ id ] = false;
            }
        }
        else
        {
            obj.removeClass('is-valid is-invalid');
            delete validationArrName[ id ] ;
        }
    }
    else
    {
        if( obj.attr('required') !== undefined )
        {
            cLog('bin richtig');
            obj.removeClass('is-valid').addClass('is-invalid');
            validationArrName[ id ] = false;
        }
    }
    cLog('validationArrName:');
    cLog(validationArrName);
}

export function checkInputOptional( obj, pattern, validationArrName = validationArr ){
    cLog('--checkInputOptional--');
    let val = obj.val(), origin = ( obj.data('origin') + '' ).trim(), id = obj.attr('id');
    obj.removeClass('is-valid is-invalid');
    if( val.length > 0 )
    {
        console.log('------');
        console.log(origin);
        console.log(val);
        console.log(val !== origin);

        if( val !== origin ) {
            cLog('validate Pattern Result for id:' + id);
            cLog(validatePattern(obj.val(), pattern));
            if (validatePattern(obj.val(), pattern)) {
                obj.removeClass('is-invalid').addClass('is-valid');
                validationArrName[id] = true;
            } else {
                obj.removeClass('is-valid').addClass('is-invalid');
                validationArrName[id] = false;
            }
        }
    }
    else
    {
        cLog('entferne elem');
        delete validationArrName[id];
    }
    cLog('validationArrName:');
    cLog(validationArrName);
}
/* preuft das Validationarray dis in alle Tiefe */
export function checkValidationArr( value = validationArr ){
    return Object.keys(value).every( y => Object.keys( value[y] ).length > 0  ? checkValidationArr(value[y]) : value[y] );
}

export function setInitialForm(){
    // validationarray leeren
    validationArr = {};
    // form zurücksetzten und staties in den INputs entfernen
    let formGuestElem =  $('#formGuest');
    formGuestElem[0].reset();
    formGuestElem.find('.is-valid,.is-invalid').removeClass('is-valid is-invalid');
    /* falls vorher email gesandrt wurde, staties entfernen */
    $('#sendConfirmation').find('.fa-check-circle,.fa-check-circle,.spinner-border').hide();
    /* wurdne mails angeuzeigt, dann die sektion verstecken */
    $('#dtMailWrapper').removeClass('isOpen');
        // Emailüberblicksymbol einblenden
    $('#showMailsInForm').show();
    /* schließt die Vorschau des Email textes -> dafür wurde in den Datatables eine neue Zeile eingebaut nach Klick auf das Lesen Sysmbol */
    $('#dtEmail').find('tr.shown').removeClass('shown').find('.fa-envelope-open-text').click();
    $('.stepTwo').hide()
    $('.stepOne').show();
    /* zeigt die Zeilen zur Anschrift wieder an */
    $('section.guestdata').show();
    /* Id des Gastes zurücksetzten auf 0 -> das heißt es ist ein komplett neuer Gast! */
    $('#gastId').val(0);
    /* zeige den Buton "Email versenden" wieder an */
    $('#addAdditionalGuest').show();
}
import '../../styles/frontend/frontend.scss'
import $ from 'jquery';
global.jQuery = $;
window.$ = $;
import 'bootstrap';
import 'tempusdominus-bootstrap-4'; // datetimepicker
/* Global site tag (gtag.js) - Google Analytics */
import './gtag';
import './../../flexslider/jquery.flexslider-min';
import './events';
$(function(){
  $('#rev_slider_1').show().revolution({
       gridheight: 400,
        sliderLayout: 'auto',
        delay: 5000,
        navigation: {

            arrows: {
                enable: true,
                style: 'zeus',
                hide_onleave: true
            },

            bullets: {
                enable: true,
                style: 'hermes',
                hide_onleave: true,
                h_align: 'center',
                v_align: 'bottom',
                h_offset: 0,
                v_offset: 20,
                space: 5
            }
        }
    });
    $('#flexslider_1').flexslider({
        slideshowSpeed:	7000,
        animationSpeed: 2000,
        directionNav: false,
        prevText: '<i class="fas fa-angle-right"></i>',
        nextText: '<i class="fas fa-angle-right"></i>',
    });
    $('#flexslider_2').flexslider({
        initDelay: 3500,
        slideshowSpeed:	7000,
        animationSpeed: 2000,
        directionNav: false,
        prevText: '<i class="fas fa-angle-right"></i>',
        nextText: '<i class="fas fa-angle-right"></i>',
    });
});